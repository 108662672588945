/* eslint-disable react/prop-types */

"use client";

import { useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  ProForm,
  ProFormInstance,
  ProFormText,
} from "@ant-design/pro-components";
import { signInEmail } from "@koble/api/identity";
import config from "@koble/config";
import ssoRouteManager from "@koble/config/src/ssoRouteManager";
import { useMessage } from "@koble/hooks";
import GoogleButton from "@koble/ui/src/CommonButton/GoogleButton";
import { TitleWithDescription } from "@koble/ui/src/CommonText";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Typography } from "antd";
import Cookies from "js-cookie";
import Link from "next/link";
import { useRouter } from "next/navigation";

const { Title } = Typography;

const SsoSignInPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { messageApi } = useMessage();
  const router = useRouter();

  const passwordInput = useRef<any>(null);
  const formRef = useRef<
    ProFormInstance<{
      email: string;
      password: string;
    }>
  >();

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: `${config.NEXT_PUBLIC_IDENTITY_SERVER}/sign-in/google`,
    ux_mode: "redirect",
  });

  return (
    <div
      style={{
        maxWidth: 400,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TitleWithDescription titleProps={{ style: { marginBottom: 10 } }}>
        Iniciar sesión
      </TitleWithDescription>
      <GoogleButton
        text="Iniciar sesión con Google"
        alt="Sign in with Google"
        onClick={() => loginWithGoogle()}
      />
      <Divider style={{ fontSize: 12 }}>o</Divider>
      <ProForm
        layout="vertical"
        style={{ width: "100%" }}
        formRef={formRef}
        autoFocusFirstInput
        onFinish={async (values) => {
          if (!executeRecaptcha) throw new Error("Recaptcha not initialized");
          const captchaResponse = await executeRecaptcha("login");

          const tokens = await signInEmail({
            username: values.email,
            password: values.password,
            captchaResponse,
            messageApi,
            identityServerUrl: config.NEXT_PUBLIC_IDENTITY_SERVER,
            errorMessage: "Ocurrió un error al iniciar sesión.",
          });

          Cookies.set("accessToken", tokens.accessToken, {
            domain: config.COOKIES_DOMAIN,
            expires: new Date(new Date().getTime() + tokens.expiresIn * 1000),
          });
          Cookies.set("refreshToken", tokens.refreshToken, {
            domain: config.COOKIES_DOMAIN,
            expires: new Date(new Date().getTime() + tokens.expiresIn * 1000),
          });

          router.push(ssoRouteManager.cb());
        }}
        submitter={{
          render: (props) => {
            return [
              <Button
                key="save"
                onClick={() => props.submit()}
                type="primary"
                style={{ width: "100%" }}
                loading={
                  props.submitButtonProps && props.submitButtonProps.loading
                }
              >
                Iniciar sesión
              </Button>,
            ];
          },
        }}
      >
        <ProFormText
          name="email"
          rules={[
            {
              required: true,
              message: "Por favor ingresa tu correo",
            },
            {
              type: "email",
              message: "Por favor ingresa un correo válido",
            },
          ]}
          placeholder="Ingresa tu correo"
          fieldProps={{
            type: "email",
            onKeyPress: (e) => {
              if (e.key === "Enter") {
                passwordInput.current.focus();
              }
            },
          }}
        />
        <ProFormText.Password
          name="password"
          rules={[
            {
              required: true,
              message: "Por favor ingresa tu contraseña",
            },
          ]}
          placeholder="Ingresa tu contraseña"
          fieldProps={{
            type: "password",
            ref: passwordInput,
            onKeyPress: (e) => {
              if (e.key === "Enter") {
                formRef.current?.submit();
              }
            },
          }}
        />
      </ProForm>
      <div
        style={{
          display: "flex",
          marginTop: 8,
          justifyContent: "flex-end",
        }}
      >
        <Link style={{ fontSize: 12 }} href={ssoRouteManager.forgotPassword()}>
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
      <Title level={5} style={{ marginTop: 32, marginBottom: 16 }}>
        ¿No tienes una cuenta aún?
      </Title>
      <Link href={ssoRouteManager.signUp()}>
        <Button style={{ width: "100%" }}>Crear cuenta</Button>
      </Link>
    </div>
  );
};

export default SsoSignInPage;
